"use client";

import { TranslationScopes } from "@finbackoffice/enums";
import NotFound500 from "screens/not-found/Error";

export default function Error() {
    const translations = {
        [TranslationScopes.Common]: {
            header_registerNow: "Register",
            menu_betSlip: "Bet Slip",
            menu_casino: "Casino",
            menu_login: "Login",
            pageName_sport: "Sport",
            pageName_live: "Live",
            pageName_livecasino: "Live Casino",
            cookies_consent_text: "This website uses cookies to improve your expierence.",
            cookies_consent_accept: "Accept",
        },
        [TranslationScopes.NotFound]: {
            not_found_page_error: "ERROR",
            not_found_page_head: "Internal Server Error!",
            not_found_page_txt: "",
        },
    };

    return <NotFound500 statusCode={500} translations={translations} />;
}
