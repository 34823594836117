"use client";

import { Page } from "next";
import { NotFound } from "components/pages/404/NotFound";
import { MainLayout } from "../layouts";

const NotFoundError: Page = ({
    translations,
}: {
    statusCode: number;
    translations: Record<string, any>;
}) => {
    return (
        <section className="page">
            <MainLayout translations={translations}>
                <NotFound statusCode={500} />
            </MainLayout>
        </section>
    );
};

export default NotFoundError;
